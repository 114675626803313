exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-index-js": () => import("./../../../src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-bn-index-js": () => import("./../../../src/pages/bn/index.js" /* webpackChunkName: "component---src-pages-bn-index-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-hi-index-js": () => import("./../../../src/pages/hi/index.js" /* webpackChunkName: "component---src-pages-hi-index-js" */),
  "component---src-pages-id-index-js": () => import("./../../../src/pages/id/index.js" /* webpackChunkName: "component---src-pages-id-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-index-js": () => import("./../../../src/pages/it/index.js" /* webpackChunkName: "component---src-pages-it-index-js" */),
  "component---src-pages-ja-index-js": () => import("./../../../src/pages/ja/index.js" /* webpackChunkName: "component---src-pages-ja-index-js" */),
  "component---src-pages-ko-index-js": () => import("./../../../src/pages/ko/index.js" /* webpackChunkName: "component---src-pages-ko-index-js" */),
  "component---src-pages-ms-index-js": () => import("./../../../src/pages/ms/index.js" /* webpackChunkName: "component---src-pages-ms-index-js" */),
  "component---src-pages-my-index-js": () => import("./../../../src/pages/my/index.js" /* webpackChunkName: "component---src-pages-my-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-pt-index-js": () => import("./../../../src/pages/pt/index.js" /* webpackChunkName: "component---src-pages-pt-index-js" */),
  "component---src-pages-ru-index-js": () => import("./../../../src/pages/ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-th-index-js": () => import("./../../../src/pages/th/index.js" /* webpackChunkName: "component---src-pages-th-index-js" */),
  "component---src-pages-tl-index-js": () => import("./../../../src/pages/tl/index.js" /* webpackChunkName: "component---src-pages-tl-index-js" */),
  "component---src-pages-top-searches-js": () => import("./../../../src/pages/top-searches.js" /* webpackChunkName: "component---src-pages-top-searches-js" */),
  "component---src-pages-top-videos-js": () => import("./../../../src/pages/top-videos.js" /* webpackChunkName: "component---src-pages-top-videos-js" */),
  "component---src-pages-tr-index-js": () => import("./../../../src/pages/tr/index.js" /* webpackChunkName: "component---src-pages-tr-index-js" */),
  "component---src-pages-vi-index-js": () => import("./../../../src/pages/vi/index.js" /* webpackChunkName: "component---src-pages-vi-index-js" */),
  "component---src-pages-zh-cn-index-js": () => import("./../../../src/pages/zh-cn/index.js" /* webpackChunkName: "component---src-pages-zh-cn-index-js" */),
  "component---src-pages-zh-tw-index-js": () => import("./../../../src/pages/zh-tw/index.js" /* webpackChunkName: "component---src-pages-zh-tw-index-js" */),
  "component---src-pages-zu-index-js": () => import("./../../../src/pages/zu/index.js" /* webpackChunkName: "component---src-pages-zu-index-js" */)
}

